
.registration-form {
    display: flex;
    padding: 100px 0;
}

.registration-form .form-wrapper h3 {
    font-family: 'Teko';
    font-size: 35px;
}

.registration-form .form-wrapper p {
    font-size: 14px;
}

.form-wrapper {
    padding: 50px;
}

.registration-form-inputs {
    display: grid;
    grid-template-columns: 50% 50%;
    column-gap: 10px;
    row-gap: 15px;
}

.registration-form-inputs input,
.registration-form-inputs input:focus {
    color: #fff;
}

.registration-form-inputs input::placeholder {
    color: #ffffff;
    font-size: 13px;
    font-weight: 300;
}

.registration-form-inputs label {
    font-size: 14px;
}

.registration-form-inputs input,
.registration-form-inputs input:focus {
    background-color: #4a5468;
    border-color: #D1D1D1;
    border-image: none !important;
    outline: none;
}

.join-label,
.follow-label {
    padding: 10px 0;
    border-bottom: 1px solid #ffffff;
    margin-bottom: 20px;
}

.join-label {
    grid-area: join-label;
}

.follow-label {
    grid-area: follow-label;
    margin-left: 20px;
}

.discord-btn,
.instagram-btn,
.twitter-btn {
    border-radius: 5px;
    padding: 5px 0;
    outline: none;
    border: none;
}

.btn-label {
    font-family: "Roboto-Bold";
    padding: 5px 0;
}

.btn-icon {
    margin-right: 10px;
}

.discord-btn {
    grid-area: discord-btn;
    background-color: #5865F2;
    color: #ffffff;
}

.instagram-btn {
    grid-area: instagram-btn;
    background: #833ab4;
    background: linear-gradient(
        to right,
        #fcb045, #fd1d1d, #833ab4
    );
    margin-left: 20px;
}

.twitter-btn,
.twitter-btn:focus,
.twitter-btn:active {
    grid-area: twitter-btn;
    background: #000000;
    margin-left: 20px;
}

.registration-button {
    grid-area: registration-button;
}

.registration-button button {
    width: 100%;
    margin-top: 20px;
}

.rodo {
    grid-area: rodo;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    padding-top: 20px;
}

.rodo input {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    margin-right: 20px;
    cursor: pointer;
}

.rodo input[type='checkbox'] {
    accent-color: #20469A;
}

.rodo label {
    cursor: pointer;
    font-size: 16px;
    font-family: "Roboto-Light";
}

.join-and-follow-section {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
    grid-template-areas:
    "join-label follow-label follow-label"
    "discord-btn instagram-btn twitter-btn"
    "rodo rodo rodo"
    "registration-button . ."
}

.side-image img {
    max-width: 100%;
}

@media screen and (max-width: 1200px) {
    .registration-form {
        flex-direction: column-reverse;
        padding: 0;
    }

    .side-image {
        display: flex;
        justify-content: center;
    }

    .side-image img {
        max-width: 100%;
    }
}

@media screen and (max-width: 767px) {
    .registration-form {
        flex-direction: column-reverse;
        padding: 0;
    }

    .side-image img {
        width: 100%;
    }

    .follow-label,
    .instagram-btn,
    .twitter-btn {
        margin-left: 0;
    }

    .instagram-btn {
        margin-bottom: 10px;
    }

    .registration-form-inputs {
        grid-template-columns: 100%;
    }

    .join-and-follow-section {
        grid-template-columns: 100%;
        grid-template-rows: auto;
        grid-template-areas:
        "join-label"
        "discord-btn"
        "follow-label"
        "instagram-btn"
        "twitter-btn"
        "rodo"
        "registration-button"
    }
}