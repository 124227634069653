.toast-body {
    background: url("../../../../assets/images/external-tournaments/toast-background.png");
    padding-bottom: 20px;
    padding-right: 20px;
    border-radius: 19px;
    display: grid;
    grid-template-columns: 75px auto 50px;
    grid-template-rows: 40px auto;
    column-gap: 20px;
    grid-template-areas:
    "ringbell congrats-text close-btn"
    "ringbell registered-text registered-text"
}

.congrats-text {
    grid-area: congrats-text;
    padding: 20px 0px;
    display: flex;
    align-items: center;
    font-family: "Roboto-Bold";
    font-size: 16px;
    color: #3BBDF5;
}

.ringbell-background {
    grid-area: ringbell;
    background: #3BBDF5;
    border-radius: 50%;
    height: 75px;
    width: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.ringbell-background img {
    height: 34px;
    width: 34px;
}

.close-btn {
    grid-area: close-btn;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.registered-text {
    grid-area: registered-text;
    font-family: "Roboto-Light";
    font-size: 16px;
}