.finished-tournaments-subpage {
    margin-top: 100px;
    width: 100%;
}

.finished-tournaments-header {
    background-color: #121625;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.finished-tournaments-header .header {
    color: #3BBDF5;
    font-family: 'Orbitron';
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
}

.finished-tournaments-header .header-text {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    color: #606F97;
    max-width: 585px;
    text-align: center;
}
.finished-tournaments-list {
    background: url("../../../assets/images/external-tournaments/finished-tournaments-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
}

.finished-tournaments-list::before {
    content: '';
    background-color: #000000;
    opacity: .5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 1;
}

@media screen and (max-width: 767px) {
    .finished-tournaments-header .header-text {
        max-width: 90%;
    }

    .finished-tournaments-list {
        margin-top: 30px;
    }
}