header {
  position: absolute;
  width: 100%;
  top: 0;
  background-color: #080c19;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar {
  width: 100%;
  height: 100%;
  max-width: 1920px;
  display: flex;
  justify-content: center;
  padding: 0 50px;
}

.desktop-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.mobile-header {
  display: none;
}

.mobile-header .hamburger-manu-btn {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 25px;
  height: 25px;
  background: transparent;
  border: none;
}

@media screen and (max-width: 1700px) {
  .desktop-header {
    display: none;
  }

  .mobile-header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
  }
}

.logo img {
  min-width: 155px;
}

.login-btn:hover,
.login-btn:focus {
  color: #fff;
  text-decoration: none;
}

.signup-btn:hover,
.signup-btn:focus {
  color: #fff !important;
  text-decoration: none;
}
.navbar-nav {
  display: flex;
  gap: 10px;
}

.navbar-left-container {
  display: flex;
  align-items: center;
  gap: 30px;
}

.navbar-nav .nav-link {
  margin-left: 0px !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #fff;
  text-transform: uppercase;
  font-family: "Roboto";
  white-space: nowrap;
}

.navbar-light .navbar-nav .nav-link.active {
  color: #3bbdf5;
}

.navbar-brand {
  margin-right: 30px;
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #3bbdf5 !important;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
  color: #fff;
}

.dropdown-item {
  font-size: 11px;
  letter-spacing: 1px;
}

.dropdown-menu {
  min-width: 8rem;
  right: 0;
  left: inherit;
}

.wallet-container {
  display: flex;
}

.wallet-box span {
  display: block;
  color: #fff;
  font-size: 12px;
  font-family: "Roboto";
}

.money-wallet-box img {
  width: 30px;
}

.wallet-box {
  margin: 0px 5px;
  color: #fff;
  display: flex;
  align-items: center;
}

.bonus-wallet img {
  width: 30px;
}

.head-user-info {
  display: flex;
  align-items: center;
  gap: 30px;
}

.head-user-info .separator-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.head-user-info .vertical-separator {
  border: 0.5px solid #293451;
  height: 73px;
}

.wallet-box i {
  font-style: normal;
  color: #fff;
  font-family: "Roboto";
}

.wallet-box h4 {
  font-size: 12px;
  font-family: "Roboto";
  margin: 0;
  margin-right: 10px;
}

.wallet-box h5 {
  font-size: 12px;
  font-family: "Roboto";
  margin: 0;
  margin-right: 5px;
}
.money-wallet-box {
  padding: 0px 5px;
}

.money-wallet {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.money-wallet-box p {
  margin: 0;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
}

.money-wallet-box p span {
  font-weight: 500;
  display: inline-block;
  color: #fff;
}

span.ac-notification.badge.badge-primary {
  margin-left: 20px;
  display: none;
}

.star-icon {
  position: absolute;
  right: -4px;
  top: -4px;
}

.auth-buttons-container {
  display: flex;
  position: absolute;
  right: 18%;
  top: 0;
  height: 100%;
}

.login-btn,
.signup-btn {
  transform: skewX(-29deg);
  border: none;
  font-size: 13px;
  font-weight: 600;
  font-family: "Roboto";
  height: 90px;
  width: 121px;
  overflow: hidden;
  position: relative;
  box-shadow: none !important;
}

.login-btn {
  background-color: #3bbdf5;
  color: #fff;
}

.signup-btn {
  background-color: #080c19;
  border-right: 1px solid #019acb;
}

.signup-btn span {
  color: #3bbdf5 !important;
}

.login-btn span,
.signup-btn span {
  display: inline-block;
  transform: skewX(29deg);
}

.signup-btn:hover,
.signup-btn:focus {
  background-color: transparent !important;
  border-right: 1px solid #019acb !important;
}
