.finished-tournament-winners {
    background-color: #1f2437;
    padding: 20px 20px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 25px;
}

.finished-tournament-winners .top-players {
    text-transform: capitalize;
    font-family: "Orbitron";
    font-size: 20px;
    font-weight: 800;
}

.finished-tournament-winners .player-label {
    color: #606F97;
}

.finished-tournament-winners .player-row {
    padding: 10px 0;
    border-bottom: 1px solid #606F97;
}

.finished-tournament-winners .player-row:first-of-type {
    border-top: 1px solid #606F97;
}

.finished-tournament-winners .player-row .name {
    font-size: 16px;
    margin-left: 10px;
}

.finished-tournament-winners .button-container {
    width: 100%;
    text-align: center;
}

.finished-tournament-winners .button-container button {
    background-color: #FF0053;
    border: none;
    padding: 10px 50px;
    margin-top: 20px;
    outline: none;
}