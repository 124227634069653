.future-tournaments-subpage {
    margin-top: 100px;
    width: 100%;
}

.future-tournaments-subpage .label {
    color: #606F97;
}

.future-tournaments-subpage .section-label {
    color: #606F97;
    border-top: 1px solid #606F97;
    margin-top: 50px;
    padding-top: 20px;
}

.future-tournaments-header {
    background-color: #121625;
    padding-top: 100px;
    padding-bottom: 100px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.future-tournaments-header .header {
    color: #3BBDF5;
    font-family: 'Orbitron';
    font-weight: 900;
    font-size: 25px;
    text-transform: uppercase;
    text-align: center;
}

.future-tournaments-header .header-text {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 13px;
    color: #606F97;
    max-width: 585px;
    text-align: center;
}

.future-tournaments-subpage .no-future-tournaments {
    text-align: center;
}