.popular-game-bg {
  background: url("../../assets/images/home/game-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px;
}
.popular-game-bg .title-page h2:after {
  left: 150px;
  width: calc(100% - 150px);
}

.game-tile .home-tile-box {
  background: url("../../assets/images/home/game-banner.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 302px;
  border: 1px solid #929292;
  border-radius: 6px;
  background-position: center;
}

.game-tile {
  margin-bottom: 70px;
}

.game-tile .home-tile-info {
  position: absolute;
  top: 50px;
  left: 50%;
  text-align: center;
}

.game-tile .home-tile-info span {
  font-family: "OhNow";
  font-size: 100px;
  line-height: 20px;
}

.game-tile .home-tile-info h6 {
  font-family: "OhNow";
  font-size: 50px;
  line-height: 20px;
  position: absolute;
  margin-top: -55px;
  text-shadow: 0px 0px 10px #000;
  left: 10px;
}

.game-tile .home-tile-info h4 {
  font-family: "Molot";
  font-size: 60px;
  margin: 0;
  line-height: 20px;
}

.game-tile .home-tile-info h2 {
  font-family: "Molot";
  font-size: 110px;
  margin: 0;
  line-height: 140px;
}

.popular-game-bg .popular-box {
  margin-bottom: 50px;
}
