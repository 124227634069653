.faq-bg {
  background: url("../../assets/images/home/faq-bg1.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 50px;
  background-attachment: fixed;
}

.faq-bg .title-page h2:after {
  left: 100px;
  width: calc(100% - 100px);
}

.faqs .accordion__button {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  font-size: 14px;
  font-family: "Roboto-Medium" !important;
}

.faqs .accordion__button:hover {
  background-color: transparent !important;
  color: #fff;
  border: 1px solid #3bbdf5;
  text-decoration: underline;
  text-decoration-color: #3bbdf5;
}

.faqs .accordion__panel {
  margin-top: -1px;
}

.faqs .accordion__panel p {
  font-size: 13px;
  letter-spacing: 1px;
  color: #fff;
  font-family: "Roboto-Light" !important;
}

.accordion__button:before {
  opacity: 1;
}

.helpsection {
  padding-top: 50px;
}

.helpsection h3 {
  color: #fff;
  font-family: "Molot";
  font-size: 30px;
  position: relative;
  padding-bottom: 10px;
}

.helpsection p {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light" !important;
}

.helpsection a {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light";
  border-bottom: 1px solid #3bbdf5;
  padding-bottom: 2px;
  margin-top: 50px;
  display: inline-block;
}

.helpsection a:hover {
  color: #fff;
  text-decoration: none;
}
