
.banner {
    width: 100%;
    margin-top: 90px; /* header height */
}

.current-tournament {
    background-color: #0c1a37;
}

.compete-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-bottom: 2px solid #C0C3CB;
    padding-bottom: 50px;
    margin-bottom: 50px;
    margin-top: 50px;
}

.compete-section h3,
.compete-section h4 {
    font-family: "Orbitron";
    text-transform: uppercase;
}

.compete-section h3 {
    font-size: 20px;
    font-weight: normal;
}

.compete-section h4 {
    font-size: 35px;
    font-weight: bold;
    text-align: center;
}

.tournament-details {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 20px;
}

.detail-tile {
    display: flex;
    border: 1px solid #ffffff;
    border-radius: 5px;
    padding: 15px;
    width: 225px;
}

.detail-tile .tile-icon {
    height: 60px;
    width: 60px;
    border: 1px solid #00ACFF;
    border-radius: 50%;
    display: flex;
    justify-content: center;
}

.detail-tile .tile-icon img {
    height: 55px;
    width: 35px;
}

.detail-tile .tile-text {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 125px;
}

.detail-tile .tile-text .label,
.detail-tile .tile-text .info {
    margin-bottom: 0px;
}

.detail-tile .tile-text .label {
    font-size: 13px;
    font-weight: normal;
}

.detail-tile .tile-text .info {
    font-size: 16px;
    font-weight: 600;
}

.detail-tile .tile-text .info.game-title {
    text-transform: uppercase;
}


@media screen and (max-width: 1300px) {
    .detail-tile {
        min-width: 200px;
    }
}

@media screen and (max-width: 767px) {
    .tournament-details {
        flex-direction: column;
    }

    .detail-tile {
        margin-bottom: 20px;
        min-width: 150px;
        width: auto;
    }

    .registration-form {
        flex-direction: column;
    }

    .side-image img {
        max-width:100%;
    }
}