.future-tournament {
    width: 100%;
    max-width: 100%;
    display: flex;
    background-color: #121625;
    border-radius: 20px;
    margin-top: 20px;
}

.future-tournament .info {
    width: 500px;
    padding: 20px;
    background-color: #1f2437;
    border-radius: 20px 0 0 20px;
}

.future-tournament .info .name {
    font-family: 'Orbitron';
    font-weight: 900;
    font-size: 20px;
    text-transform: capitalize;
}

.future-tournament .info .game-title {
    color: #48516B;
    font-size: 13px;
}

.future-tournament .info .info-with-icons {
    display: flex;
    padding: 20px 0;
    margin-bottom: 25px;
    margin-top: 20px;
}

.future-tournament .info .info-with-icons .info-tile {
    display: flex;
}

.future-tournament .info .info-with-icons .info-tile .info-icon {
    height: 38px;
    width: 38px;
}

.future-tournament .info .info-with-icons .info-tile .info-text {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 30px;
}

.future-tournament .info .info-with-icons .info-tile .info-text .label {
    color: #48516B;
    font-size: 13px;
}

.future-tournament .info .info-with-icons .info-tile .info-text .value {
    font-size: 'Roboto';
    font-weight: 600;
}


.future-tournament .info .button-container {
    padding-right: 20px;
}

.future-tournament .info .button-container button {
    background-color: #3BBDF5;
    color: white;
    width: 100%;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 800;
    border: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.future-tournament .info .button-container .btn:disabled {
    opacity: 1;
}

.future-tournament .info .end-date {
    color: #48516B;
    font-family: 'Orbitron';
    font-weight: 900;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
    padding-top: 30px;
}

.future-tournament .banner-container {
    max-width: 100%;
    width: 100%;
}

.future-tournament .banner-container img {
    border-radius: 0 20px 20px 0;
    max-width: 100%;
    height: 100%;
}


@media screen and (max-width: 1220px) {
    .future-tournament .banner-container img {
        object-fit: cover;
        object-position: left;
    }
}

@media screen and (max-width: 767px) {
    .future-tournament {
        flex-flow: wrap-reverse;
    }

    .future-tournament .info {
        width: 100%;
    }

    .future-tournament .banner-container img {
        border-radius: 20px 20px 0 0;
    }

    .future-tournament .info .info-with-icons {
        flex-direction: column;
        row-gap: 25px;
    }
}