.nav-link {
    display: flex;
    align-items: center;
}

.user-info-section {
    display: flex;
    align-items: center;
}

.navbar-nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.notification-section {
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px;
    border-left: 1px solid #293451;
    margin: 20px 0;
}

.mobile-header {
    height: 90px;
}