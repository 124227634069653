.sponsors {
    background-color: #2c2c2c;
    padding-bottom: 50px;
}

.sponsors h2 {
    font-family: Orbitron;
    font-weight: 900;
    font-size: 20px;
    text-align: center;
    padding-top: 20px;
    text-transform: uppercase;
}

.sponsor-logos {
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.sponsor-logo {
    height: 200px;
    width: fit-content;
}

@media screen and (max-width: 767px) {
    .sponsor-logos {
        flex-direction: column;
        row-gap: 50px;
        align-items: center;
    }
}