.aboutus {
  background: #111 url("../../assets/images/home/legal-bg.jpg");
  padding: 100px 0px;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px;
}

.aboutus-descp {
  margin-top: 20px;
}

.aboutus-descp h4 {
  color: #fff;
  margin-bottom: 20px;
  font-family: "Molot";
  font-size: 25px;
}
.aboutus-descp p {
  color: #fff;
  font-size: 14px;
  font-weight: 100;
  line-height: 1.7;
}
.about-f li {
  margin-bottom: 10px;
  font-weight: 100;
  font-size: 14px;
}
.about-f {
  color: #fff;
}
ul.about-f {
  padding-left: 25px;
  padding-top: 20px;
}
img.about-image {
  margin-top: 20px;
  border-radius: 10px;
  margin-left: 20px;
}
.aboutus .page-title-dark {
  padding-bottom: 70px;
}

.aboutus .title-page h2:after {
  left: 220px;
  width: calc(100% - 220px);
}

@media screen and (max-width: 767px) {
  img.about-image {
    margin-left: 0px;
  }
}
