.title-page .linee:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 425px;
  right: 0;
  height: 0.6em;
  border-top: 2px solid white;
  z-index: 9;
}
