.features-section {
  padding: 100px 0px;
}

.f-content .page-title {
  text-align: center;
  padding-bottom: 30px;
  position: relative;
}
.f-content .page-title h2 {
  font-size: 55px;
  color: #3f36a385;
  opacity: 0.1;
  margin: 0;
  line-height: 60px;
  text-transform: uppercase;
}
.f-content .page-title h3 {
  margin-top: -40px;
  font-size: 35px;
  text-transform: uppercase;
}
.f-content .page-title h3 span {
  color: #8f1c1c;
}
.feature-descp h4 {
  font-size: 30px;
  margin-bottom: 20px;
}
.feature-descp p {
  font-size: 14px;
  font-weight: 300;
}
.feature-descp {
  padding: 20px;
}

.feature-list li {
  font-weight: 300;
  margin-bottom: 10px;
  font-size: 13px;
}
.feature-descp p {
  font-size: 13px;
  font-weight: 300;
  line-height: 25px;
}
ul.feature-list {
  padding: 16px;
}
.feature-descp h4 {
  font-size: 30px;
  margin-bottom: 20px;
  line-height: 40px;
}
img.feature-img {
  width: 100%;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  padding: 20px;
}
.f-content {
  padding-top: 20px;
}
.feature-descp {
  padding: 20px 0px 0px 0px;
}
.feature-descp.uvh {
  padding: 40px 0px 0px 0px;
}
.f-content {
  padding-top: 30px;
  padding-bottom: 40px;
}
.team-title h2:after {
  left: 220px;
  width: calc(100% - 220px);
}
.responsible-title h2:after {
  left: 425px;
  width: calc(100% - 425px);
}
