.counter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 200px 0;
}

.counter {
    width: 1000px;
    max-width: 100%;
    align-items: center;
    justify-content: space-around;
    display: flex;
}

.counter-header {
    font-family: 'Orbitron';
    font-size: 20px;
    font-weight: 900;
    text-transform: uppercase;
    margin-bottom: 20px;
}

.days,
.hours,
.minutes,
.seconds {
    font-family: "Roboto-Medium";
    font-size: 60px;
    width: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-right: 50px;
    padding-left: 50px;
}

.days,
.hours,
.minutes {
    border-right: 2px solid #000000;
}

.counter-number {
    font-size: 80px;
    color: #00C2FF;
    font-family: 'Orbitron';
    font-weight: 900;
    cursor: default;
}

.counter-label {
    font-family: 'Roboto';
    font-weight: 400;
    font-size: 16px;
    width: 100px;
    color: white;
    text-transform: capitalize;
    width: 100%;
}

@media screen and (max-width: 1200px) {
    .counter {
        width: 100%;
    }

    .days, .hours, .minutes, .seconds {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .counter {
        width: 100%;
    }
}