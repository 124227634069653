.footer {
  background: #0d111e;
}

.copy-right {
  text-align: left;
  border-top: 1px solid #2a2e3a;
  padding: 15px 0px;
  font-weight: 100;
}

.copy-right p {
  color: #3b404e;
  margin: 0;
  font-size: 12px;
  letter-spacing: 1.5px;
  font-family: "Roboto-Light";
}

.top-footer {
  background: #0d111e;
  padding: 0 0 40px;
  margin: 0 20px;
}

.top-footer .logo {
  width: 10vw;
}

.top-footer p {
  color: #fff;
  font-size: 12px;
  font-weight: 100;
  line-height: 1.7;
  max-width: 320px;
  letter-spacing: 1.5px;
  font-family: "Roboto-Light";
}

.top-footer .logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 60px;
}

.top-footer .footer-one img {
  width: 405px;
}

.footer-links {
  background-color: #131727;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: contain;
  border-radius: 35px;
  padding: 60px 20px;
  justify-content: space-evenly;
  align-items: flex-start;
}

.footer-links h4 {
  font-family: "Roboto-Medium";
  font-size: 16px;
  font-weight: 500;
  line-height: 25.2px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3bbdf5;
  margin-bottom: 10px;
}

ul.footer-list {
  padding-left: 0px;
  list-style-type: none;
}

ul.footer-list li {
  padding: 6px 0px;
  margin: 0;
}

ul.footer-list li a {
  font-size: 16px;
  font-weight: 300;
  color: #9fa3a7;
  letter-spacing: 1.5px;
  padding-bottom: 4px;
  font-family: "Roboto-Light";
}

ul.footer-list li a:hover {
  color: #fff;
  text-decoration: none;
  border-bottom: 2px solid #3bbdf5;
}

ul.footer-list li img {
  width: 20px;
  margin-right: 3px;
}

.top-footer .footer-info-container {
  margin-top: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-info-container .footer-info-text {
  width: 60%;
  font-size: 16px;
  font-weight: 400;
  line-height: 33.6px;
  text-align: left;
  color: #60687d;
}

.app-stores {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.app-stores img {
  width: 200px;
}

.payment {
  width: 100px;
  position: absolute;
  top: 15px;
  right: 0;
}

.copy-right {
  position: relative;
}

.copy-right span {
  position: absolute;
  left: 0;
  color: #ccc;
}

.sponsors-box {
  border-bottom: 1px solid #2a2e3a;
  padding-bottom: 20px;
}

.sponsors-box .sponser-item {
  width: 20%;
}

.sponsors-container {
  background: #0d111e;
  padding-top: 20px;
}

.sponsors-box .sponser-item img {
  transition: all 0.5s;
  cursor: pointer;
  margin: 0px auto;
  max-width: 150px;
  filter: grayscale(100%) brightness(0.5);
}
.sponsors-box .sponser-item img:hover {
  filter: none !important;
}

.sponsors-container ul.slick-dots {
  display: none !important;
}
/* .sponsors-box  .slick-slider .slick-list .slick-slide:focus{
    outline: none!important;
    border: none!important;
    box-shadow:none!important;
}
.sponsors-box .sponser-item img:focus {
    outline: none!important;
} */
.slick-slide > div:focus,
.sponsors-box .sponser-item:focus {
  outline: none !important;
}

@media screen and (max-width: 768px) {
  .top-footer .footer-one img {
    margin-bottom: 0;
    width: 205px;
  }

  .footer-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .footer-links > div {
    width: 250px !important;
  }

  .footer-info-container {
    flex-direction: column;
    align-items: center;
    gap: 50px;
  }

  .footer-info-container .footer-info-text {
    width: 100%;
  }
}
