.user-already-registered {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px;
}

.user-already-registered h1 {
    font-family: 'Orbitron';
    text-transform: uppercase;
    font-size: 35px;
    font-weight: 900;
    padding-bottom: 20px;
}

.user-already-registered p {
    font-size: 16px;
}

.user-already-registered .red-btn {
    width: 270px;
}

@media screen and (max-width: 767px) {
    .user-already-registered .red-btn {
        width: 100%;
    }
}