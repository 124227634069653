.legal {
  background: url("../../assets/images/home/legal-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 150px !important;
  padding-bottom: 50px !important;
}

.legal .title-page h2:after {
  left: 350px;
  width: calc(100% - 350px);
}

.legal .title-page.term-title h2:after {
  left: 450px;
  width: calc(100% - 450px);
}
/* 
.legal .title-page.legal-title h2:after {
    left: 280px;
    width: calc(100% - 280px);
} */

.legal .title-page.help-title h2:after {
  left: 150px;
  width: calc(100% - 150px);
}

.legal .title-page {
  padding-bottom: 30px;
}

.legal .title-page p {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light" !important;
  max-width: 700px;
  padding-top: 30px;
}

.legal-content-box h2 {
  color: #fff;
  font-family: "Molot";
  font-size: 30px;
  position: relative;
  padding-bottom: 10px;
}

.legal-content-box p {
  color: #fff;
  font-size: 13px;
  letter-spacing: 1px;
  font-family: "Roboto-Light" !important;
}

.legal-content-box h3 {
  color: #fff;
  font-family: "Molot";
  font-size: 25px;
  position: relative;
  padding-bottom: 10px;
  padding-top: 20px;
}

.legal-content-box h4 {
  color: #fff;
  font-family: "Roboto-Light" !important;
  font-size: 16px;
  letter-spacing: 1.5px;
}

.legal-content-box h3 {
  color: #fff;
  font-family: "Molot";
  font-size: 25px;
  position: relative;
  padding-bottom: 10px;
  padding-top: 20px;
}

.legal-content-box h4 {
  color: #fff;
  font-family: "Roboto-Light" !important;
  font-size: 16px;
  letter-spacing: 1.5px;
  padding-bottom: 15px;
}

ul.legal-list {
  padding-left: 15px;
}

ul.legal-list li {
  color: #fff;
  padding: 5px 5px;
  font-size: 13px;
  letter-spacing: 1px;
}

ul.legal-no-list {
  padding-left: 15px;
  list-style-type: decimal;
}

ul.legal-no-list li {
  color: #fff;
  padding: 5px 5px;
  font-size: 13px;
  letter-spacing: 1px;
}

.legal-content-box h5 {
  color: #fff;
  font-family: "Roboto-Light" !important;
  font-size: 15px;
  letter-spacing: 1.5px;
  padding-bottom: 10px;
}

.legal .title-page.legal-title .linenew:after {
  left: 295px !important;
  width: calc(100% - 280px);
}
.legal .title-page.legal-title .linenew1:after {
  left: 380px !important;
  width: calc(100% - 280px);
}
