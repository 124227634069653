.external-tournament-registration-modal {
    position: relative;
    background-color: #131625;
    background-image: url("../../../../../assets/images/external-tournaments/modal-background.png");
    height: 375px;
    width: 750px;
    border-radius: 20px;
    text-align: center;
    padding-bottom: 50px;

    display: grid;
    grid-template-areas:
        ". close-btn"
        "ringbell ."
        "congrats ."
        "modal-text ."
        "important-text ."
        "red-btn .";
    grid-template-columns: 1fr 60px;
    grid-template-rows: 60px 75px 1fr 1fr 1fr 1fr;
}

.blurred-background {
    z-index: 10;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgb(13, 26, 55, .5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.external-tournament-registration-modal .ringbell-background {
    grid-area: ringbell;
    display: flex;
    justify-self: center;
    margin-top: -20px;
}

.external-tournament-registration-modal .congrats {
    grid-area: congrats;
    color: #3BBDF5;
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 900;
    margin-top: 20px;
}

.external-tournament-registration-modal .text {
    grid-area: modal-text;
    font-family: 'Roboto';
    color: #606F97;
    font-size: 16px;
}

.external-tournament-registration-modal .important-text {
    grid-area: important-text;
    font-family: 'Roboto';
    font-weight: 800;
    color: #606F97;
    font-size: 16px;
}

.external-tournament-registration-modal .button-wrapper {
    grid-area: close-btn;
    width: 100%;
    height: 40px;
}

.external-tournament-registration-modal .red-btn {
    grid-area: red-btn;
    display: flex;
    justify-self: center;
    justify-content: center;
}

.external-tournament-registration-modal .close-btn {
    cursor: pointer;
    margin-top: 5px;
}
