.small-counter-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.small-counter-wrapper .counter {
    max-width: 100%;
    width: 150px;
    align-items: center;
    justify-content: space-around;
    display: flex;
    justify-content: center;
}

.small-counter-wrapper .counter-number {
    color: #00C2FF;
    font-family: 'Orbitron';
    font-weight: 900;
    cursor: default;
    margin-right: 3px;
    margin-left: 3px;
    width: 28px;
    text-transform: uppercase;
    font-size: 16px;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .small-counter-wrapper .counter {
        width: 100%;
    }
}